<template>
  <div class="resources">
    
    <div class="heading">
      <h2>Resources</h2>
    </div>

    <div class="content">
      
      <button class="vCard">
        <a :href="vCard.url" target="_blank">
          <p>Download vCard</p>
          <p>{{ vCard.size }} vCard file</p>
          <img src="../assets/cloud.png" class="cloud1">
        </a>
      </button>
      
      <!-- <button class="lCard"> 
        <p>Loan Criteria Sheet</p>
        <p>2.3 MB PDF</p>
        <img src="../assets/cloud.png" class="cloud2">
      </button> -->

    </div>

  </div>
</template>

<script>
export default {
  name: 'Resources',
  data() {
    return {
      // vCard: {
      //   url: "/Peter-Matthews.vcf",
      //   size: "9.06 KB"
      // },
      // vCard: {
      //   url: "/Aysan-Pamir-Ozel.vcf",
      //   size: "25.3 KB"
      // },
      // vCard: {
      //   url: "/Predrag-Savovic-Jovanovic.vcf",
      //   size: "23.38 KB"
      // },
      // vCard: {
      //   url: "/Laurent-Chocron.vcf",
      //   size: "24.73 KB"
      // },
      // vCard: {
      //   url: "/Jack-Hodgkin.vcf",
      //   size: "25.73 KB"
      // }
      vCard: {
        url: "/Sam-Shooter.vcf",
        size: "6.18 KB"
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resources {
  background: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin: 0 1rem 3rem;
  padding-bottom: 1rem;
}
.heading {
  color: #817878;
  padding: 1rem;
}
.heading h2 {
  font-size: 1.5rem;
}
.content {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  align-items: center;
}
button {
  background: #C3AC6B;
  border-radius: 60px;
  width: 90%;
  height: 60px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  border: none;
  padding: 10px 30px;
  position: relative;
}
button a {
  color: #fff;
  text-decoration: none;
}
button p {
  margin: 0;
  font-size: 20px;
}
button p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 400;
}
.cloud1 {
  width: 40px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.cloud2 {
  width: 40px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) {
  .resources {
    margin: 0 2rem 4rem;
  }
  .heading h2 {
    font-size: 2.5rem;
  }
}
</style>